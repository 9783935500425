import * as React from "react";
import { GlobalStyles } from "../components/GlobalStyles";
import { ImageGallery } from "../components/ImageGallery";
import { Footer } from "../components/PageUtils/Footer";
import { Header } from "../components/PageUtils/Header";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <React.Fragment>
      <SEO title="Welcome" keywords={[`wedding`, `jack`, `nicola`]} />
      <GlobalStyles />
      <Header />
      <ImageGallery />
      <Footer />
    </React.Fragment>
  );
};

export default IndexPage;
